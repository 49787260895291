import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [0,17,18];

export const dictionary = {
		"/(app)": [20,[2]],
		"/calendar": [~30,[13]],
		"/change_password": [31],
		"/faces/[id]": [~32,[14]],
		"/map": [33,[15]],
		"/(encyclopedias)/nationalities": [~21,[4]],
		"/(encyclopedias)/nationalities/[name]": [~22,[3,5]],
		"/news": [~34,[16]],
		"/news/[id]": [~35,[16]],
		"/(static_pages)/page/[id]": [~29,[12]],
		"/profile/(app)": [~36,[17]],
		"/profile/(app)/articles": [~38,[17]],
		"/profile/(app)/articles/[id]": [~39,[17]],
		"/profile/(app)/documents": [~40,[17]],
		"/profile/(app)/events": [~41,[17]],
		"/profile/(app)/events/control": [~43,[17]],
		"/profile/(app)/events/control/[id]": [~44,[17]],
		"/profile/(app)/events/[id]": [~42,[17]],
		"/profile/(app)/favorite": [~45,[17]],
		"/profile/(app)/friends": [~46,[17]],
		"/profile/loading": [58],
		"/profile/(app)/messages": [47,[17]],
		"/profile/(app)/region": [~48,[17]],
		"/profile/(auth)/register": [53,[18]],
		"/profile/(auth)/register/form": [~54,[18]],
		"/profile/(auth)/register/theme": [~55,[18]],
		"/profile/(auth)/register/welcome_email": [57,[18]],
		"/profile/(auth)/register/welcome": [56,[18]],
		"/profile/(app)/translations": [~49,[17]],
		"/profile/(app)/translations/control": [~51,[17]],
		"/profile/(app)/translations/control/[id]": [~52,[17]],
		"/profile/(app)/translations/[id]": [~50,[17]],
		"/profile/(app)/[id]": [~37,[17]],
		"/(encyclopedias)/religions": [~23,[6]],
		"/(encyclopedias)/religions/buddhism": [24,[3,7]],
		"/(encyclopedias)/religions/christianity": [25,[3,8]],
		"/(encyclopedias)/religions/islam": [26,[3,9]],
		"/(encyclopedias)/religions/judaism": [27,[3,10]],
		"/(encyclopedias)/religions/other": [28,[3,11]],
		"/search": [59,[19]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';